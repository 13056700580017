.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 600px;

    @media screen and (max-width: 1000px) {
        @media (min-height: 700px) {
            height: 650px;
        }
        @media (min-height: 800px) {
            height: 750px;
        }
        @media (min-height: 900px) {
            height: 850px;
        }
    }
}