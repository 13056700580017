@use './mixins';

.navbar {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__menu {
        width: 550px;
        height: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        
        &__link {
            color: white;
            text-decoration: none;
            font-weight: 500;
            
            &:hover {
                opacity: 0.5;
            }
            
            @media screen and (max-width: 1000px) {
                
            }
            
            @media screen and (min-width: 1000px) {
                font-size: 15pt;
            }
        }
        
    }
    &__divider {
        @include mixins.divider(100%, 2px)
    }
}

@media screen and (max-width: 600px) {
    .navbar {
        width: 350px;

        nav {
            width: 100%;
            height: 60px;
        }
    }
}

