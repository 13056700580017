.swiper {
    overflow: visible;
    
    &-container {
        display: flex;
        align-items: center;
        
        @media screen and (max-width: 1000px) {
            width: 100%;
            margin-top: 25px;
        }

        @media screen and (min-width: 1000px) {
            width: 100vw;
            margin-top: 50px;
        }
    }
    
    &-button-next {
        margin-right: 20px;
        position: static;
        color:white;
        opacity: 50%;

        @media screen and (max-width: 1000px) {
            display: none;
        }
    }
    
    &-button-prev {
        margin-left: 20px;
        position: static;
        color:white;
        opacity: 50%;

        @media screen and (max-width: 1000px) {
            display: none;
        }
    }
    
    @media screen and (max-width: 1000px) {
        width: 200px;
    }

    @media screen and (min-width: 1000px) {
        width: 60vw;
    }
}







// .swiper-container {
//     margin-top: 50px;
//     display: flex;
//     align-items: center;

//     @media screen and (max-width: 1000px) and (min-height: 700px) {
//         padding-top: 25px;
//     }
//     overflow: hidden;

// }


// .swiper {
//     width: 60vw;
//     overflow: visible;
// }

// // .swiper-slide {
// //     width: 200px;
// //     opacity: 0.25;
// // }

// .swiper-button-next {
//     margin-right: 20px;
//     position: relative;
//     color:white;
//     opacity: 50%;
// }

// .swiper-button-prev {
//     margin-left: 20px;
//     position: relative;
//     color:white;
//     opacity: 50%;
// }

// @media only screen and (max-width: 600px) {

//     .swiper-container {
//         margin: 0px;
//         margin-top: 40px;
//     }

//     .swiper {
//         width: 200px;
//         overflow: visible;
//     }

//     .swiper-button-next {
//         display: none;
//     }
//     .swiper-button-prev {
//         display: none;
//     }
    
//     // .swiper-slide {
//     //     opacity: 0.25;
//     //     transition: all 0.5s ease;

//     //     h3, span {
//     //         display: none;
//     //         transition: all 0.5s ease;
//     //     }

//     // }
// }

// @media screen and (min-width: 1500) {
//     .swiper-container {
//         margin-top: 100px;
//     }

//     .swiper-slide {
//     }
// }