@use './mixins';

.project-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    
    &__img {

        @media screen and (max-width: 1000px) {
            width: 200px;
            height: 200px;
        }

        @media screen and (min-width: 1000px) {
            width: 250px;
            height: 250px;
        }
    }

    &__label {
        width: 150px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: space-evenly;
        color: white;

        &__header {
            font-style: italic;
            width: 150px;
            font-size: 15pt;
        }

        &__span {
            width: 150px;
            font-size: 10pt;
        }

        &__btn {
            @include mixins.btn-default;
        }


        @media screen and (max-width: 1000px) {
            width: 200px;
            height: 175px;
        }

        @media screen and (min-width: 1000px) {
            width: 250px;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }


    }
}


.swiper-slide {
    opacity: 0.25;
    
    .project-card__label {
        display: none;
    }
    
    @media screen and (min-width: 1000px) {
        pointer-events: none;
    }

    &-visible {
        opacity: 1;
        pointer-events: auto;

        .project-card__label {
            @media screen and (max-width: 1000px) {
                display: flex;
            }
        }

        &:hover {
            .project-card__img {
                @media screen and (min-width: 1000px) {
                    transition: all 0.5s ease;
                    opacity: 0.25;
                }
            }
            .project-card__label {
                @media screen and (min-width: 1000px) {
                    display: flex;
                }
            }
        }
    }
}


// @media screen and (min-width: 0px) {

//     .swiper-slide-visible {
//         opacity: 1;
//         transition: all 0.5s ease;


//         h3, span {
//             display: block;
//             transition: all 0.5s ease;
//         }

        
//     }

//     .swiper-slide-next {
//         opacity: 0.25;
//         transition: all 0.5s ease;

//         h3, span {
//             display: none;
//             transition: all 0.5s ease;
//         }
//     }
    
//     .project-card {



//         &__label {


//             span {
//                 margin: 0 auto;
//                 width: 150px;
//                 margin-top: 10px;
//                 font-size: 8pt;
//             }
//         }
        
//     }
// }

// @media screen and (min-width: 1000px){
    
//     .swiper-slide {

//         &-next {
//             opacity: 1;
//             transition: all 0.5s ease;
        
        
//             h3, span {
//                 display: block;
//                 transition: all 0.5s ease;
//             }
//         }

//         &-visible:hover {
            
//             img {
//                 transition: all 0.5s ease;
//                 opacity: 20%;
//                 // -webkit-box-shadow: 0px 0px 50px 5px #DBB2FF; 
//                 // box-shadow: 0px 0px 50px 5px #DBB2FF;
//             }
//             .project-card__label {
//                 display: block;
//             }
//         }
//     }


//     .project-card {

    
//         img {
//             height: 200px;
//             width: 200px;
//         }
    
//         &__label {
//             display: none;
//             position: absolute;
//             margin-top: auto;
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%, -50%);
    
//             h3 {
//                 font-size: 12pt;
//                 font-style: italic;
//             }
//             span {
//                 font-size: 0.8rem;
//             }
//         }
    
//     }


// }