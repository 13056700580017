@use './mixins';

.banner {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    
    @media screen and (max-width: 1000px) {
        width: 310px;    
        height: 150px;   
    }
    
    @media screen and (min-width: 1000px) {
        width: 550px;
        height: 225px;
        margin-top:50px;
    }

    &__header {
        @include mixins.header-default;
    }

    &__text {
        @include mixins.text-narrative
    }


}