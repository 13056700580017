@mixin header-default {
    color: white;
    text-align: center;
    text-shadow: 0px 0px 20px rgba(219,178,255,0.25);
    letter-spacing: 0.1rem;

    @media screen and (max-width: 1000px) {
        font-size: 40pt;
    }
    
    @media screen and (min-width: 1000px) {
        font-size: 70pt;
    }
}

@mixin text-narrative {
    color: white;
    text-align: center;

    @media screen and (max-width: 1000px) {
        font-size: 12pt;
    }
    
    @media screen and (min-width: 1000px) {
        font-size: 15pt;
    }
}

@mixin btn-default {
    text-decoration: none;
    width: 120px;
    margin: 0 auto;
    font-size: 0.9rem;
    font-weight: 700;
    color: white;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.25);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    &:hover {
        color: #6200ee;
        background-color: rgba(255, 255, 255, 1);
        transition: all 0.25s ease;
    }
}

@mixin divider($width, $weight) {
    width: $width;
    height: $weight;
    background-color: rgba(255, 255, 255, 0.5);
}