@use './mixins';

.contact {
    &__header {
        @include mixins.header-default;
    }
    &__form {
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        
        &__input, &__text {
            width: 80%;
            font-size: 15px;
            padding: 5px;
            color: white;
            text-align: center;

            
            &, &:hover, &:focus, &:active {
                outline: none;
                background-color: transparent;
                border-style: solid;
                border: 0;
                border-bottom: 2px solid rgba(255, 255, 255, 0.55);
            }

            &::placeholder {
                color: white;
            }

            
            &:focus {
                outline: none;
                background-color: transparent;
                border-style: solid;
                border: 0;
                border-bottom: 2px solid rgba(255, 255, 255, 0.55);
            }

            &:focus::placeholder {
                color: rgba(255, 255, 255, 0.55);
            }
        }

        &__input {
        }

        &__text {
            // height: 18px;

            // &:invalid {
            //     height: 18px;
            //     transition: all 1s ease-in-out;
            // }

            // &:focus {
            //     height: 200px;
            //     transition: all 1s ease-in-out;
            // }

            
            // &:focus {
            //     height:calc(100% - 90px);
            //     transition: all 2s ease-in-out;
            //     -webkit-transition: all 2s ease-in-out;
            // }
        }

        &__btn {
            @include mixins.btn-default;
        }
        
    }
    @media screen and (max-width: 1000px) {
        width: 310px;
    }

    @media screen and (min-width: 1000px) {
        width: 550px;
    }
}