:root {
    --main-color: #0b0b0b;
}

* {
    margin: 0;
    padding: 0;
    font-family: "arboria", sans-serif;
}

.app {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-image: linear-gradient(to bottom right, #6200ee, #985eff);
    // background-image: url('https://serving.photos.photobox.com/3494388342db60622a6042e8770d5298b17955d22ed1798ec630f1beabfac7449025f33a.jpg');
    // background-repeat: no-repeat;
    // background-size: cover;
}